body { 
  font-family: 'Noto Sans KR', sans-serif; 
  font-weight: 500;
}

.description {
    line-height: 1.7em;
}

.sidebar {
  font-weight: bold;
  min-width: 250px;
  text-shadow: 10px 10px 10px gray;
}

.App {
  display: grid;
  grid-template-columns: 1fr 10fr;
}

.sidebar {
  border: 1px solid black;
  width: 100%;
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.mobile_title {
  display: none;
}
a { 
  color: inherit; 
  text-decoration:none; 
}   

:root {
  font-size: 2rem;
}

li {
  margin : 0 0 1.3em 0 ;
}

    html, body {
      margin : 0 ;
      padding : 0;
  }

  .project {
      width : 65%;
      display : grid;
      grid-template-columns : 35% 65% ;
      border : 0px solid white ;
      border-radius : 1em ;
      margin : 0 0 2em 0 ;
      padding : 1em;
      box-shadow:  0 0 30px rgba(100, 100, 100,.5);
  }

  .center {
    text-align: center;
  }

  .img_tech {
    width: 350px;
    display: block;
    margin: auto;
    padding : 0 0 90px 0 ;
  }

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  padding : 0 2em 0 0 
}

.sub {
  font-style: italic;
  font-size: .9rem;
  padding: .4em 0 0 0;
}

.sub_ul {
  font-size: .8em;
}

@media (max-width : 1000px){
  body {
    width: 100%;
  }
  :root {
    font-size : 1rem
  }
  .img_tech {
    display: none;
  }
  .project {
    width : 90%;
    grid-template-columns : 1fr ;
  }
  .mobile_title {
    display: block;
    text-align: center;
    font-size: 2em;
  }
  .sidebar{
    display: none;
  }

  .App {
    grid-template-columns: 1fr;
  }
}

.project, .sidebar {
  background-color: white;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(243, 238, 238);
}

.mainTitle {
  background-image: linear-gradient(-90deg,#4c7c68 0,#35c3ff 30%,#fda399 50%,#76d880 70%,#a2a860 90%,#4c7c68 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: bolder;
  font-size: 1.2rem;  
}

.strong_font > h1 {
  text-shadow: 5px 5px 10px #e9e7e7;
}

img {
  max-width: 100%;
}